:root {
  /* Colors */
  --white: #edeff3;
  --black: #232323;
  --lightblack: #414141;
  --light-transparent-black: rgba(0, 0, 0, 0.1);
  --light-transparent-white: rgba(255, 255, 254, 0.2);
  --yellow: #f9c74f;
  --darkyellow: #f0ac0d;
  --lightyellow: #fffeb6;
  --blue: #4751a6;
  --lightblue: #6671d4;

  /* Durations */
  --superquick: 0.1s;
  --quick: 0.2s;
  --slow: 0.4s;
}

body {
  margin: 0;
  color: var(--black);
  background-color: var(--yellow);
  font-family: "Muli", sans-serif;
  font-weight: 200;
  line-height: 1.2;
  /* animation: color-transition 60s infinite; */
}

.svg-inline--fa {
  margin: 0 0.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

h1 {
  font-size: 3rem;
  font-weight: 900;
}

ul {
  padding: 0;
}

figure {
  margin: 0;
}

img.rounded {
  border-radius: 12px;
}

.attribution {
  font-size: 12px;
  font-style: italic;
}

@keyframes color-transition {
  0% {
    background-color: var(--yellow);
  }
  50% {
    background-color: var(--lightyellow);
  }
  100% {
    background-color: var(--yellow);
  }
}
