:local(.Software) {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 0.5rem;

  padding: 3rem 1rem;
}
@media only screen and (min-width: 720px) {
  :local(.Software) {
    grid-template-columns: 1fr 1fr;
    padding: 4rem 3rem;
    gap: 1rem;
  }
  /* Make images appear on left and right every second time */
  :local(.Software):nth-child(2n) :local(.Cover) {
    grid-row: 1;
    grid-column: 2;
  }
  :local(.Software):nth-child(2n) :local(.Info) {
    grid-row: 1;
    grid-column: 1;
  }
}

:local(.Cover) {
  place-self: center;
}
:local(.Cover) img {
  object-fit: contain;
  width: 340px;
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}
