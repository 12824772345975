:local(.Recording) {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: start;
  gap: 0.5rem;

  padding: 3rem 1rem;
}
@media only screen and (min-width: 720px) {
  :local(.Recording) {
    grid-template-columns: 1fr 1fr;
    padding: 4rem 3rem;
    gap: 1rem;
  }
  /* Make images appear on left and right every second time */
  :local(.Recording):nth-child(2n) :local(.CoverAndLinks) {
    grid-row: 1;
    grid-column: 2;
  }
  :local(.Recording):nth-child(2n) :local(.Info) {
    grid-row: 1;
    grid-column: 1;
  }
}

:local(.CoverAndLinks) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
:local(.Cover) {
  margin-bottom: 0.5rem;
}
:local(.Cover) img {
  object-fit: cover;
  width: 200px;
  height: 200px;
}
:local(.Links) {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
:local(.Name) {
  /* padding: 0.5rem; */
  font-weight: 700;
  /* background-color: white; */
  /* font-size: 2.5rem; */
}
/* @media only screen and (min-width: 920px) {
  :local(.MoveLeft) {
    margin-left: -4rem;
    margin-top: -2rem;
  }
} */
