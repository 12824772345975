:local(.App) {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 920px;
  margin: 0 auto;
}

:local(.Section) {
  padding: 1rem 0 3rem 0;
}

:local(.Section h1) {
  padding-top: 0.5rem;
  text-align: center;
}

@media only screen and (min-width: 920px) {
  :local(.App) {
    padding: 1rem 10rem;
  }

  :local(.Section h1) {
    width: fit-content;
    text-align: left;
  }

  :local(.StickyHeading) {
    position: sticky;
    top: 0rem;
    z-index: 1;
    padding-top: 0;
  }
}
