:local(.ListItem) {
  padding: 1rem;
}

:local(.ListItem):nth-child(2n + 2) {
  background-color: var(--light-transparent-white);
  border-radius: 12px;
}
:local(.ListItem):last-child {
  border-bottom: 1px solid var(--light-transparent-white);
}

:local(.ListItem__detail) {
  color: var(--lightblack);
  font-size: 0.6em;
}
