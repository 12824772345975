:local(.Link) {
  color: var(--blue);
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform var(--superquick) ease-in-out,
    color var(--superquick) ease-in-out;
}
:local(.Link):hover {
  transform: scale(1.075) rotateZ(-1deg);
  color: var(--lightblue);
}
:local(.ImageLink):hover {
  transform: scale(1.025) rotateZ(-1deg);
}
