.accordion__button {
  cursor: pointer;
  padding: 0 1rem;
  display: inline-block;
  transition: transform var(--superquick) ease-in-out;
}

.accordion__button:hover {
  transform: scale(1.1) rotateZ(-1deg);
  background-color: rgba(255, 255, 255, 0.3);
}

.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 1rem;
  margin: 0 0.5rem 0 0;
}

.accordion__arrow::after,
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: "";
}

.accordion__arrow::before {
  left: 4px;
  transform: rotate(225deg);
}

[aria-expanded="true"] .accordion__arrow::before,
[aria-selected="true"] .accordion__arrow::before {
  transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg);
}

[aria-expanded="true"] .accordion__arrow::after,
[aria-selected="true"] .accordion__arrow::after {
  transform: rotate(225deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
  transition: transform var(--slow) ease;
}

.accordion__button {
  border-bottom: 2px solid transparent;
}
.accordion__button[aria-expanded="true"] {
  border-color: var(--lightblack);
}

:root {
  /* Colors */
  --white: #edeff3;
  --black: #232323;
  --lightblack: #414141;
  --light-transparent-black: rgba(0, 0, 0, 0.1);
  --light-transparent-white: rgba(255, 255, 254, 0.2);
  --yellow: #f9c74f;
  --darkyellow: #f0ac0d;
  --lightyellow: #fffeb6;
  --blue: #4751a6;
  --lightblue: #6671d4;

  /* Durations */
  --superquick: 0.1s;
  --quick: 0.2s;
  --slow: 0.4s;
}

body {
  margin: 0;
  color: #232323;
  color: var(--black);
  background-color: #f9c74f;
  background-color: var(--yellow);
  font-family: "Muli", sans-serif;
  font-weight: 200;
  line-height: 1.2;
  /* animation: color-transition 60s infinite; */
}

.svg-inline--fa {
  margin: 0 0.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

h1 {
  font-size: 3rem;
  font-weight: 900;
}

ul {
  padding: 0;
}

figure {
  margin: 0;
}

img.rounded {
  border-radius: 12px;
}

.attribution {
  font-size: 12px;
  font-style: italic;
}

@-webkit-keyframes color-transition {
  0% {
    background-color: #f9c74f;
    background-color: var(--yellow);
  }
  50% {
    background-color: #fffeb6;
    background-color: var(--lightyellow);
  }
  100% {
    background-color: #f9c74f;
    background-color: var(--yellow);
  }
}

@keyframes color-transition {
  0% {
    background-color: #f9c74f;
    background-color: var(--yellow);
  }
  50% {
    background-color: #fffeb6;
    background-color: var(--lightyellow);
  }
  100% {
    background-color: #f9c74f;
    background-color: var(--yellow);
  }
}

._2vt-8KzJmndD8Z_jA0Czgk {
  font-size: 1.5rem;
  width: 100%;
}

._1YyEIhifaM66sSV7jbQpfA {
  color: var(--blue);
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform var(--superquick) ease-in-out,
    color var(--superquick) ease-in-out;
}
._1YyEIhifaM66sSV7jbQpfA:hover {
  transform: scale(1.075) rotateZ(-1deg);
  color: var(--lightblue);
}
._2uj_vwiMs5NMtU2DP6-FFO:hover {
  transform: scale(1.025) rotateZ(-1deg);
}

._1YcFyUQR_9sJIBXrqbozLv {
  font-size: 2rem;
  list-style-type: none;
}

._8nJa9lKWoxDC4VUEOFylF {
  padding: 1rem;
}

._8nJa9lKWoxDC4VUEOFylF:nth-child(2n + 2) {
  background-color: var(--light-transparent-white);
  border-radius: 12px;
}
._8nJa9lKWoxDC4VUEOFylF:last-child {
  border-bottom: 1px solid var(--light-transparent-white);
}

._3gCYdNL85wcueH0Xqi8ahv {
  color: var(--lightblack);
  font-size: 0.6em;
}

._39Y67_xXQaAAUSkcOiMDqD {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;

  padding: 3rem 1rem;
}
@media only screen and (min-width: 720px) {
  ._39Y67_xXQaAAUSkcOiMDqD {
    grid-template-columns: 1fr 1fr;
    padding: 4rem 3rem;
    grid-gap: 1rem;
    gap: 1rem;
  }
  /* Make images appear on left and right every second time */
  ._39Y67_xXQaAAUSkcOiMDqD:nth-child(2n) .Q3JmDbaB5pLjjs2cENvUQ {
    grid-row: 1;
    grid-column: 2;
  }
  ._39Y67_xXQaAAUSkcOiMDqD:nth-child(2n) ._3JDiOGGSWIXNH-DHYbJy2f {
    grid-row: 1;
    grid-column: 1;
  }
}

.Q3JmDbaB5pLjjs2cENvUQ {
  place-self: center;
}
.Q3JmDbaB5pLjjs2cENvUQ img {
  object-fit: contain;
  width: 340px;
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}

.NE2ZbAiKpIs_Xas8Rh8FM {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: start;
  grid-gap: 0.5rem;
  gap: 0.5rem;

  padding: 3rem 1rem;
}
@media only screen and (min-width: 720px) {
  .NE2ZbAiKpIs_Xas8Rh8FM {
    grid-template-columns: 1fr 1fr;
    padding: 4rem 3rem;
    grid-gap: 1rem;
    gap: 1rem;
  }
  /* Make images appear on left and right every second time */
  .NE2ZbAiKpIs_Xas8Rh8FM:nth-child(2n) .iBYPdJv_ORTf1OHknKtuK {
    grid-row: 1;
    grid-column: 2;
  }
  .NE2ZbAiKpIs_Xas8Rh8FM:nth-child(2n) .hGXCqOc0wxzBKxlkS8FvZ {
    grid-row: 1;
    grid-column: 1;
  }
}

.iBYPdJv_ORTf1OHknKtuK {
  display: flex;
  flex-direction: column;
  align-items: center;
}
._2pMtlYkPtLtu76NHQ6CXy_ {
  margin-bottom: 0.5rem;
}
._2pMtlYkPtLtu76NHQ6CXy_ img {
  object-fit: cover;
  width: 200px;
  height: 200px;
}
._2TvLlqm26poUjJBF9lbynh {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
._3Lsga4iAPQhsRDnnopXFwe {
  /* padding: 0.5rem; */
  font-weight: 700;
  /* background-color: white; */
  /* font-size: 2.5rem; */
}
/* @media only screen and (min-width: 920px) {
  :local(.MoveLeft) {
    margin-left: -4rem;
    margin-top: -2rem;
  }
} */

table {
  border-collapse: collapse;
}

tr:nth-child(2n + 2) {
  background-color: var(--light-transparent-white);
  border-radius: 12px;
}
tr:last-child {
  border-bottom: 1px solid var(--light-transparent-white);
}

td {
  padding: 1rem 0.5rem;
}
td:not(:first-child):not(:last-child) {
  word-break: break-word;
}
td:first-child {
  border-radius: 2px 0 0 2px;
  padding-left: 1rem;
}
td:last-child {
  border-radius: 0 2px 2px 0;
  padding-right: 1rem;
}

@media only screen and (max-width: 1100px) {
  table,
  thead,
  tbody,
  th,
  tr,
  td {
    display: block;
  }

  table {
    text-align: center;
  }

  tr {
    padding: 0.5rem;
  }

  td {
    padding: 0;
  }
}

._268PTvg9wQahr3U8ATVz95 {
  text-align: center;
  padding-top: 1rem;
}

.vqlBjiTaFvPj9OM3ThNg0 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: var(--yellow);
  /* animation: color-transition 60s infinite; */

  font-size: 1.25rem;
}

.vqlBjiTaFvPj9OM3ThNg0 a {
  text-decoration: none;
  color: var(--black);
}

._2939QxN4gnt6_FKV2_FwQ6 {
  margin: 0.5rem;
  padding: 0.25rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-bottom: 2px solid var(--blue);

  transition: transform var(--superquick) ease-in-out,
    border-bottom var(--superquick) ease-in-out;
}
._2939QxN4gnt6_FKV2_FwQ6:hover {
  transform: scale(1.075) rotateZ(-1deg);
  border-bottom-color: var(--lightblue);
}

@media only screen and (min-width: 920px) {
  .vqlBjiTaFvPj9OM3ThNg0 {
    justify-content: flex-end;
    align-items: flex-end;
  }
  ._2939QxN4gnt6_FKV2_FwQ6 {
    margin: 1rem;

    font-size: 1.25rem;
    border-bottom: 4px solid var(--blue);
  }
}

@media only screen and (min-width: 1200px) {
  ._2939QxN4gnt6_FKV2_FwQ6 {
    font-size: 2rem;
  }
}

._3TjpjWCh_1vdhkT_o3HiNE {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 920px;
  margin: 0 auto;
}

._3TGmpJ-jP147hjlPSFIrck {
  padding: 1rem 0 3rem 0;
}

._3TGmpJ-jP147hjlPSFIrck h1 {
  padding-top: 0.5rem;
  text-align: center;
}

@media only screen and (min-width: 920px) {
  ._3TjpjWCh_1vdhkT_o3HiNE {
    padding: 1rem 10rem;
  }

  ._3TGmpJ-jP147hjlPSFIrck h1 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: left;
  }

  ._26cbF2ESu54_y0nntvr210 {
    position: -webkit-sticky;
    position: sticky;
    top: 0rem;
    z-index: 1;
    padding-top: 0;
  }
}

