:local(.NavMenu) {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: var(--yellow);
  /* animation: color-transition 60s infinite; */

  font-size: 1.25rem;
}

:local(.NavMenu) a {
  text-decoration: none;
  color: var(--black);
}

:local(.NavMenuItem) {
  margin: 0.5rem;
  padding: 0.25rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-bottom: 2px solid var(--blue);

  transition: transform var(--superquick) ease-in-out,
    border-bottom var(--superquick) ease-in-out;
}
:local(.NavMenuItem):hover {
  transform: scale(1.075) rotateZ(-1deg);
  border-bottom-color: var(--lightblue);
}

@media only screen and (min-width: 920px) {
  :local(.NavMenu) {
    justify-content: flex-end;
    align-items: flex-end;
  }
  :local(.NavMenuItem) {
    margin: 1rem;

    font-size: 1.25rem;
    border-bottom: 4px solid var(--blue);
  }
}

@media only screen and (min-width: 1200px) {
  :local(.NavMenuItem) {
    font-size: 2rem;
  }
}
