.accordion__button {
  cursor: pointer;
  padding: 0 1rem;
  display: inline-block;
  transition: transform var(--superquick) ease-in-out;
}

.accordion__button:hover {
  transform: scale(1.1) rotateZ(-1deg);
  background-color: rgba(255, 255, 255, 0.3);
}

.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 1rem;
  margin: 0 0.5rem 0 0;
}

.accordion__arrow::after,
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: "";
}

.accordion__arrow::before {
  left: 4px;
  transform: rotate(225deg);
}

[aria-expanded="true"] .accordion__arrow::before,
[aria-selected="true"] .accordion__arrow::before {
  transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg);
}

[aria-expanded="true"] .accordion__arrow::after,
[aria-selected="true"] .accordion__arrow::after {
  transform: rotate(225deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
  transition: transform var(--slow) ease;
}

.accordion__button {
  border-bottom: 2px solid transparent;
}
.accordion__button[aria-expanded="true"] {
  border-color: var(--lightblack);
}
